import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import close from '../../assets/img/Close.svg';
import language from '../../assets/img/Language-w.svg';
import languageBlue from '../../assets/img/Language.svg';
import menu from '../../assets/img/Menu.svg';
import style from './Navbar.module.scss';
export default function Navbar() {
    const [showMenu, setShowMenu] = useState(false);
    const { t, i18n } = useTranslation();
    const [navClass, setNavClass] = useState(`${style.defaultNavClass}`);
    const navRef = useRef(null);
    const location = useLocation();
    const menuIcon = showMenu ? close : menu;
    const changeLng = (lng) => {
        i18n.changeLanguage(lng);
    };

    const handleLanguageChange = (event) => {
        const selectedLanguage = event.target.value;
        changeLng(selectedLanguage);
    };

    useEffect(() => {
        setShowMenu(false) 
    }, [location.pathname || location.hash])

    useEffect(() => {
        if (location.pathname === '/') {
            setNavClass(`${style.changeNavBg}`);
        } else {
            setNavClass(`${style.defaultNavClass}`);
        }
    }, [location.pathname || location.hash])

    return (
        <>
            <nav ref={navRef} className={navClass}>
                <div className={`d-flex`}>
                    <Link to="/" className={style.logo}></Link>
                </div>
                <div className={showMenu ? `show dropdown ${style.mAuto0}` : `dropdown ${style.mAuto0}`}>
                    <img id="dropdown-basic" aria-expanded={showMenu} data-toggle="dropdown" alt="" src={menuIcon} className={style.menu} onClick={() => setShowMenu(!showMenu)}></img>
                    <div x-placement="bottom-end" aria-labelledby="dropdown-basic" className={showMenu ? `dropdown-menu show ${style.menuList}` : `dropdown-menu`} data-popper-reference-hidden="false" data-popper-escaped="false" data-popper-placement="bottom-end">
                        <Link to="/#about-eunodata" data-rr-ui-dropdown-item="" className={`dropdown-item`} onClick={() => setShowMenu(false)}>{t("content.AboutUs")}</Link>
                        <Link to="/Solutions" data-rr-ui-dropdown-item="" className={`dropdown-item`}>{t("content.Solutions-Nav")}</Link>
                        <Link to="/UseCases" data-rr-ui-dropdown-item="" className={`dropdown-item`}>{t("content.Use-Cases")}</Link>
                        <Link to="/News" data-rr-ui-dropdown-item="" className={`dropdown-item`}>{t("content.News-Title")}</Link>
                        <Link to="/Career" data-rr-ui-dropdown-item="" className={`dropdown-item`}>{t("content.Career")}</Link>
                        <Link to="/ContactUs" data-rr-ui-dropdown-item="" className={`dropdown-item`}>{t("content.ContactUs")}</Link>
                        <div className={style.item}>
                            <div src={languageBlue} className={style.languageBlue}></div>
                            <span className={style.customDropdown}>
                                <select onChange={handleLanguageChange}>
                                    <option value="en">{t("content.EN")}</option>
                                    <option value="zh">{t("content.ZH")}</option>
                                </select>
                            </span>
                        </div>
                    </div>
                </div>
                <div className={`${style.itemList} ${style.itemHide} ${i18n.language === "zh" ? "chinese" : "english"}`}>
                    <Link to="/#about-eunodata" className={style.item}>{t("content.AboutUs")}</Link>
                    <Link to="/Solutions" className={style.item}>{t("content.Solutions-Nav")}</Link>
                    <Link to="/UseCases" className={style.item}>{t("content.Use-Cases")}</Link>
                    <Link to="/News" className={style.item}>{t("content.News-Title")}</Link>
                    <Link to="/Career" className={style.item}>{t("content.Career")}</Link>
                    <Link to="/ContactUs" className={style.item}>{t("content.ContactUs")}</Link>
                    <div className={style.item}>
                        <div src={language} className={style.language}></div>
                        <span className={style.customDropdown}>
                            <select onChange={handleLanguageChange}>
                                <option value="en">{t("content.EN")}</option>
                                <option value="zh">{t("content.ZH")}</option>
                            </select>
                        </span>
                    </div>
                </div>
            </nav>
        </>
    )
}